// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-misc-emojiply-politica-de-privacidad-js": () => import("../src/pages/misc/emojiply/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-misc-emojiply-politica-de-privacidad-js" */),
  "component---src-pages-misc-emojiply-privacy-policy-js": () => import("../src/pages/misc/emojiply/privacy-policy.js" /* webpackChunkName: "component---src-pages-misc-emojiply-privacy-policy-js" */),
  "component---src-pages-projects-backcountry-js": () => import("../src/pages/projects/backcountry.js" /* webpackChunkName: "component---src-pages-projects-backcountry-js" */),
  "component---src-pages-projects-basement-guardian-js": () => import("../src/pages/projects/basement-guardian.js" /* webpackChunkName: "component---src-pages-projects-basement-guardian-js" */),
  "component---src-pages-projects-connect-sense-js": () => import("../src/pages/projects/connect-sense.js" /* webpackChunkName: "component---src-pages-projects-connect-sense-js" */),
  "component---src-pages-projects-emojiply-js": () => import("../src/pages/projects/emojiply.js" /* webpackChunkName: "component---src-pages-projects-emojiply-js" */),
  "component---src-pages-projects-steals-js": () => import("../src/pages/projects/steals.js" /* webpackChunkName: "component---src-pages-projects-steals-js" */),
  "component---src-pages-projects-u-by-moen-js": () => import("../src/pages/projects/u-by-moen.js" /* webpackChunkName: "component---src-pages-projects-u-by-moen-js" */),
  "component---src-pages-talks-accessibility-in-ios-js": () => import("../src/pages/talks/accessibility-in-ios.js" /* webpackChunkName: "component---src-pages-talks-accessibility-in-ios-js" */),
  "component---src-pages-talks-como-viajar-a-indiana-por-200-js": () => import("../src/pages/talks/como-viajar-a-indiana-por-200.js" /* webpackChunkName: "component---src-pages-talks-como-viajar-a-indiana-por-200-js" */),
  "component---src-pages-talks-github-actions-js": () => import("../src/pages/talks/github-actions.js" /* webpackChunkName: "component---src-pages-talks-github-actions-js" */),
  "component---src-pages-talks-swiftui-js": () => import("../src/pages/talks/swiftui.js" /* webpackChunkName: "component---src-pages-talks-swiftui-js" */),
  "component---src-pages-talks-this-is-how-we-do-it-js": () => import("../src/pages/talks/this-is-how-we-do-it.js" /* webpackChunkName: "component---src-pages-talks-this-is-how-we-do-it-js" */),
  "component---src-pages-talks-xcui-testing-js": () => import("../src/pages/talks/xcui-testing.js" /* webpackChunkName: "component---src-pages-talks-xcui-testing-js" */)
}

